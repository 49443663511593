<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="top">
          <button
            type="button"
            class="btn btn-info"
            @click="$router.push('/local/new')"
          >
            Novo Registro
          </button>
        </div>
        <div>
          <h4 v-if="this.$route.query.res == 'add'">
            Registro criado com sucesso!
          </h4>
          <h4 v-else-if="this.$route.query.res == 'edit'">
            Registro alterado com sucesso!
          </h4>
          <h4 v-else-if="this.$route.query.res == 'remove'">
            Registro removido com sucesso!
          </h4>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th width="80%">Local</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(local, index) in list.data" :key="index">
              <td>{{ local.section }}{{ local.nr }}</td>
              <td>
                <div local="group">
                  <router-link
                    :to="{ name: 'edit_local', params: { id: local.id } }"
                    class="btn btn-primary"
                    >Editar
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../mixins/routesValidationMixin";
import httpRequestMixin from "../../mixins/httpRequestMixin";
import LocalizacaoEstoqueRepository from "@/repositories/v2/LocalizacaoEstoqueRepository";

export default {
  data() {
    return {
      list: {
        type: Object,
        default: null,
      },
      localizacaoEstoqueRepository: LocalizacaoEstoqueRepository.build(),
    };
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  created() {
    this.grid();
    this.localizacaoEstoqueRepository = LocalizacaoEstoqueRepository.build();
  },
  methods: {
    grid() {
      this.localizacaoEstoqueRepository
        .getLocalizacoesEmEstoque()
        .then((response) => {
          this.list = response.data;
          console.log(this.list);
        })
        .catch(function (error) {
          //   window.location.href = "/dashboard";
          console.error(error);
        });
    },
  },
};
</script>
